import React from 'react';
import * as ant from 'antd';
import ProjectCard from "./ProjectCard";
import {Language, Project} from "../Data/types";
import * as data from "../Data/Data";

type ProjectSectionProps = {
    lang: Language
}

function ProjectSection(props: ProjectSectionProps) {
    const lang = props.lang;

    const gameJamList = data.gameJams.map((project:Project) =>
        <ProjectCard key={project[lang].name} project={project} lang={lang} className={"projectCardSmall"}/>
    )
    const persoProjectsList = data.persoProjects.map((project:Project) =>
        <ProjectCard key={project[lang].name} project={project} lang={lang} className={"projectCardSmall"}/>
    )

    const persoProjectsTitle: string = lang === Language.en ? "Personal projects" : "Projets personnels";
    const SectionTitle: string = lang === Language.en ? "Projects" : "Projets";

    return (
        <ant.Flex align={"center"} vertical={true}>
            <h1 className={"SectionTitle"}>{SectionTitle}</h1>
            <ProjectCard project={data.alhfa} lang={lang} className={"projectCardBig"}></ProjectCard>
            <div className={"ProjectSection"}>
                <h1 className={"ProjectSectionTitle"}>Game Jams</h1>
                <ant.Flex justify={"space-evenly"} className={"ProjectSubSection"} wrap={"wrap"}>
                    {gameJamList}
                </ant.Flex>
            </div>
            <ProjectCard project={data.plane} lang={lang} isPlane={true} className={"projectCardBig"}></ProjectCard>
            <div className={"ProjectSection"}>
                <h1 className={"ProjectSectionTitle"}>{persoProjectsTitle}</h1>
                <ant.Flex justify={"space-evenly"} className={"ProjectSubSection"} wrap={"wrap"}>
                    {persoProjectsList}
                </ant.Flex>
            </div>
        </ant.Flex>
    )
}

export default ProjectSection;